import Collapsible from 'react-collapsible';
import Moment from 'react-moment';

const Questions = ({ startCourseDate }) => {
  return (
    <section className='questions' data-aos='fade-up'>
      <div className='questions__container'>
        <h2 className='questions__title section-title'>FAQ</h2>
        <Collapsible
          transitionTime={300}
          classParentString='questions__accordion Collapsible'
          trigger='Я не делал до этого приложения, смогу ли я? '>
          <p className='questions__answer'>
            - Курс проходят даже дети от 14-ти лет, раз у них получилось, то и у вас тоже. Мы поможем вам разобраться со
            всеми трудностями и дадим план, как развиваться дальше.
          </p>
        </Collapsible>
        <Collapsible
          transitionTime={300}
          classParentString='questions__accordion Collapsible'
          trigger='Смогу ли я совмещать курс с обучением или с работой? '>
          <p className='questions__answer'>
            - Курс не привязан к вашему графику. Вы можете уделять пару часов в неделю или же обучаться целый день. Всё сделано чтобы
            вы могли без ущерба к своей работе или учебе проходить данный курс. 
          </p>
        </Collapsible>
        <Collapsible
          transitionTime={300}
          classParentString='questions__accordion Collapsible'
          trigger='Кому подойдет курс? '>
          <p className='questions__answer'>
            - Курс подойдёт абсолютно всем, у кого есть желание изучить Flutter. Возраст здесь не имеет никакого значение.
          </p>
        </Collapsible>
        <Collapsible
          transitionTime={300}
          classParentString='questions__accordion Collapsible'
          trigger='Чем наш курс отличается от других?'>
          <p className='questions__answer'>
            - Мы занимаемся мобильной разработкой как основное направление нашей деятельности,
            поэтому через нас проходит много различных кейсов при разработке заказов или продуктов.
            В отличии от других популярных платформ, мы специализируемся на Flutter.
          </p>
        </Collapsible>
        <Collapsible
          transitionTime={300}
          classParentString='questions__accordion Collapsible'
          trigger='Что я получу после прохождения курса?'>
          <p className='questions__answer'>
            - Вы получите необходимый багаж знаний и понятие о том, как должна проходить разработка
            серьезных проектов, чтобы найти стажировку в компании. Доступ к материалу курсов
            остаётся навсегда. При правильном подходе вы сможете алгоритмизировать процесс и
            быстрее набрать опыт.
          </p>
        </Collapsible>
        <Collapsible
          transitionTime={300}
          classParentString='questions__accordion Collapsible'
          trigger='Как и когда я буду учиться?'>
          <p className='questions__answer'>
            - Курс можно начать в любой момент. 
            Формат обучения последовательный, после изучения материала урока вы сразу переходите к практике.
          </p>
        </Collapsible>
        <Collapsible
          transitionTime={300}
          classParentString='questions__accordion Collapsible'
          trigger='Сколько времени занимает учëба?'>
          <p className='questions__answer'>
            - Курс можно пройти за 3 недели, если уделять каждый день по 2 часа. В течении этого времени менторы проверяют ваш код и подводят итоги
            вашей работы.
          </p>
        </Collapsible>
      </div>
    </section>
  );
};

export default Questions;
