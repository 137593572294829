import { HashLink as Link } from 'react-router-hash-link';

const Menu = ({ onClick, openModal, isMenuVisible }) => {
  return (
    <div className={`main-header__menu menu ${isMenuVisible ? 'menu--open' : ''}`}>
      <div
        className='menu__background modal__background'
        onClick={() => {
          if (window.innerWidth < 1200) {
            onClick();
          }
          document.documentElement.classList.remove('scroll-off');
        }}
      />
      <nav className='menu__body'>
        <h3 className='menu__title-mobile'>Меню</h3>
        <ul className='menu__list'>
          <li className='menu__item'>
            <Link
              className='menu__link'
              to='/#portfolio'
              smooth
              onClick={() => {
                if (window.innerWidth < 1200) {
                  onClick();
                }
                document.documentElement.classList.remove('scroll-off');
              }}>
              Обучение
            </Link>
          </li>
          <li className='menu__item'>
            <Link
              className='menu__link'
              to='/#course-program'
              smooth
              onClick={() => {
                if (window.innerWidth < 1200) {
                  onClick();
                }
                document.documentElement.classList.remove('scroll-off');
              }}>
              Программа курса
            </Link>
          </li>
          <li className='menu__item'>
            <Link
              className='menu__link'
              to='/#send-request'
              smooth
              onClick={() => {
                if (window.innerWidth < 1200) {
                  onClick();
                }
                document.documentElement.classList.remove('scroll-off');
              }}>
              Стоимость
            </Link>
          </li>
          <li className='menu__item'>
            {/* <Link
              className='menu__sign-up-btn'
              to='/#send-request'
              smooth>
              Записаться на курс
            </Link> */}
            <button
              className='menu__sign-up-btn'
              onClick={() => {
                if (window.innerWidth < 1200) {
                  onClick();
                }
                openModal();
                document.documentElement.classList.add('scroll-off');
              }}>
              Записаться на курс
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Menu;
