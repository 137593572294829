import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useState } from 'react';

const StudentsWinners = () => {
  const [showAll1, setShowAll1] = useState(false);
  const [showAll2, setShowAll2] = useState(false);
  const [showAll3, setShowAll3] = useState(false);
  return (
    <section className='students-winners' data-aos='fade-up'>
      <div className='students-winners__container'>
        <h2 className='students-winners__title section-title'>Студенты, получившие выигрыш</h2>
        <p className='students-winners__description'>
          Отличившиеся студенты попадают в данный список, с каждым можно связаться и посмотреть на
          реализованный проект.
        </p>
        <Splide
          options={{
            perPage: 2,
            perMove: 2,
            gap: 30,
            fixedWidth: '470px',
            arrows: true,
            pagination: true,
            breakpoints: {
              1199: {
                gap: 15,
                fixedWidth: '352px'
              },
              767: {
                perMove: 1,
                gap: 8,
                perPage: 1,
                fixedWidth: '100%'
              }
            }
          }}>
          <SplideSlide>
            <div className='students-winners__card'>
              <div className='students-winners__student-info-container'>
                <div className='students-winners__avatar-wrapper'>
                  <img
                    src={require('../assets/images/student-avatar-1.jpg')}
                    alt=''
                    className='students-winners__student-avatar'
                  />
                </div>
                <div className='students-winners__students-info-wrapper'>
                  <div className='students-winners__student-info'>
                    <span className='students-winners__student-name'>Смагулов Дидар</span>
                    <a
                      className='students-winners__social-link students-winners__social-link--github'
                      href='https://github.com/DiJJ'
                      target='_blank'
                      rel='noreferrer'
                      title='github'>
                      <svg
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                          fillRule='evenodd'
                          clip-rule='evenodd'
                          d='M9 0C4.0275 0 0 4.12937 0 9.22764C0 13.3109 2.57625 16.7597 6.15375 17.9824C6.60375 18.0631 6.7725 17.7863 6.7725 17.5441C6.7725 17.3249 6.76125 16.5982 6.76125 15.8254C4.5 16.2522 3.915 15.2602 3.735 14.7412C3.63375 14.4759 3.195 13.6569 2.8125 13.4378C2.4975 13.2647 2.0475 12.838 2.80125 12.8264C3.51 12.8149 4.01625 13.4954 4.185 13.7723C4.995 15.1679 6.28875 14.7758 6.80625 14.5335C6.885 13.9337 7.12125 13.53 7.38 13.2993C5.3775 13.0687 3.285 12.2728 3.285 8.74319C3.285 7.73969 3.63375 6.9092 4.2075 6.26326C4.1175 6.03257 3.8025 5.08674 4.2975 3.81794C4.2975 3.81794 5.05125 3.57571 6.7725 4.76377C7.4925 4.55615 8.2575 4.45234 9.0225 4.45234C9.7875 4.45234 10.5525 4.55615 11.2725 4.76377C12.9937 3.56418 13.7475 3.81794 13.7475 3.81794C14.2425 5.08674 13.9275 6.03257 13.8375 6.26326C14.4113 6.9092 14.76 7.72815 14.76 8.74319C14.76 12.2843 12.6562 13.0687 10.6537 13.2993C10.98 13.5877 11.2613 14.1414 11.2613 15.0065C11.2613 16.2407 11.25 17.2326 11.25 17.5441C11.25 17.7863 11.4187 18.0746 11.8687 17.9824C13.6554 17.3639 15.2079 16.1866 16.3077 14.6161C17.4076 13.0456 17.9995 11.1611 18 9.22764C18 4.12937 13.9725 0 9 0Z'
                          fill='#F9F9F9'
                        />
                      </svg>
                    </a>
                  </div>
                  {/* <span className='students-winners__expiration-date'>Окончил курс 03/08/21</span> */}
                </div>
              </div>
              <span className='students-winners__gift'>Выиграл iPhone и стажировку</span>
              <p className='students-winners__feedback' id='feedback-1'>
                “Курс по флаттеру был мне очень полезен. Я смог разобраться в основах мобильной
                разработки, то как верстают, как использовать API и в целом как проходит рабочий
                процесс в командах, проводятся code review. Курс мне понравился, особенно учитывая,
                что это первый поток. Совершенно точно, я могу посоветовать данный курс другим, не
                было жалко ни копейки.”
              </p>
              <button
                className='students-winners__show-all'
                onClick={() => {
                  setShowAll1(!showAll1);
                  document
                    .getElementById('feedback-1')
                    .classList.toggle('students-winners__feedback--show-all');
                }}>
                {showAll1 ? 'Свернуть' : 'Показать полностью'}
              </button>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className='students-winners__card'>
              <div className='students-winners__student-info-container'>
                <div className='students-winners__avatar-wrapper'>
                  <img
                    src={require('../assets/images/student-avatar-2.jpg')}
                    alt=''
                    className='students-winners__student-avatar'
                  />
                </div>
                <div className='students-winners__students-info-wrapper'>
                  <div className='students-winners__student-info'>
                    <span className='students-winners__student-name'>Ермахан Ансаган</span>
                    <a
                      className='students-winners__social-link students-winners__social-link--github'
                      href='https://github.com/yermakhanansagan'
                      target='_blank'
                      rel='noreferrer'
                      title='github'>
                      <svg
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                          fillRule='evenodd'
                          clip-rule='evenodd'
                          d='M9 0C4.0275 0 0 4.12937 0 9.22764C0 13.3109 2.57625 16.7597 6.15375 17.9824C6.60375 18.0631 6.7725 17.7863 6.7725 17.5441C6.7725 17.3249 6.76125 16.5982 6.76125 15.8254C4.5 16.2522 3.915 15.2602 3.735 14.7412C3.63375 14.4759 3.195 13.6569 2.8125 13.4378C2.4975 13.2647 2.0475 12.838 2.80125 12.8264C3.51 12.8149 4.01625 13.4954 4.185 13.7723C4.995 15.1679 6.28875 14.7758 6.80625 14.5335C6.885 13.9337 7.12125 13.53 7.38 13.2993C5.3775 13.0687 3.285 12.2728 3.285 8.74319C3.285 7.73969 3.63375 6.9092 4.2075 6.26326C4.1175 6.03257 3.8025 5.08674 4.2975 3.81794C4.2975 3.81794 5.05125 3.57571 6.7725 4.76377C7.4925 4.55615 8.2575 4.45234 9.0225 4.45234C9.7875 4.45234 10.5525 4.55615 11.2725 4.76377C12.9937 3.56418 13.7475 3.81794 13.7475 3.81794C14.2425 5.08674 13.9275 6.03257 13.8375 6.26326C14.4113 6.9092 14.76 7.72815 14.76 8.74319C14.76 12.2843 12.6562 13.0687 10.6537 13.2993C10.98 13.5877 11.2613 14.1414 11.2613 15.0065C11.2613 16.2407 11.25 17.2326 11.25 17.5441C11.25 17.7863 11.4187 18.0746 11.8687 17.9824C13.6554 17.3639 15.2079 16.1866 16.3077 14.6161C17.4076 13.0456 17.9995 11.1611 18 9.22764C18 4.12937 13.9725 0 9 0Z'
                          fill='#F9F9F9'
                        />
                      </svg>
                    </a>
                  </div>
                  {/* <span className='students-winners__expiration-date'>Окончил курс 03/08/21</span> */}
                </div>
              </div>
              <span className='students-winners__gift'>Получил стажировку</span>

              <p className='students-winners__feedback' id='feedback-2'>
                “Курс прошёл замечательно, простые задания, которые являются основами разработки на
                Flutter, хорошие менторы, которые помогут и подскажут решение твоей любой проблемы.
                Советую новым ученикам не лениться и просто делать задания и вы освоите навыки
                мобильной разработки с этим курсом.”
              </p>
              <button
                className='students-winners__show-all'
                onClick={() => {
                  setShowAll2(!showAll2);
                  document
                    .getElementById('feedback-2')
                    .classList.toggle('students-winners__feedback--show-all');
                }}>
                {showAll2 ? 'Свернуть' : 'Показать полностью'}
              </button>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className='students-winners__card'>
              <div className='students-winners__student-info-container'>
                <div className='students-winners__avatar-wrapper'>
                  <img
                    src={require('../assets/images/student-avatar-3.jpg')}
                    alt=''
                    className='students-winners__student-avatar'
                  />
                </div>
                <div className='students-winners__students-info-wrapper'>
                  <div className='students-winners__student-info'>
                    <span className='students-winners__student-name'>Абылайхан Жетес</span>
                    <a
                      className='students-winners__social-link students-winners__social-link--github'
                      href='https://github.com/abikko'
                      target='_blank'
                      rel='noreferrer'
                      title='github'>
                      <svg
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                          fillRule='evenodd'
                          clip-rule='evenodd'
                          d='M9 0C4.0275 0 0 4.12937 0 9.22764C0 13.3109 2.57625 16.7597 6.15375 17.9824C6.60375 18.0631 6.7725 17.7863 6.7725 17.5441C6.7725 17.3249 6.76125 16.5982 6.76125 15.8254C4.5 16.2522 3.915 15.2602 3.735 14.7412C3.63375 14.4759 3.195 13.6569 2.8125 13.4378C2.4975 13.2647 2.0475 12.838 2.80125 12.8264C3.51 12.8149 4.01625 13.4954 4.185 13.7723C4.995 15.1679 6.28875 14.7758 6.80625 14.5335C6.885 13.9337 7.12125 13.53 7.38 13.2993C5.3775 13.0687 3.285 12.2728 3.285 8.74319C3.285 7.73969 3.63375 6.9092 4.2075 6.26326C4.1175 6.03257 3.8025 5.08674 4.2975 3.81794C4.2975 3.81794 5.05125 3.57571 6.7725 4.76377C7.4925 4.55615 8.2575 4.45234 9.0225 4.45234C9.7875 4.45234 10.5525 4.55615 11.2725 4.76377C12.9937 3.56418 13.7475 3.81794 13.7475 3.81794C14.2425 5.08674 13.9275 6.03257 13.8375 6.26326C14.4113 6.9092 14.76 7.72815 14.76 8.74319C14.76 12.2843 12.6562 13.0687 10.6537 13.2993C10.98 13.5877 11.2613 14.1414 11.2613 15.0065C11.2613 16.2407 11.25 17.2326 11.25 17.5441C11.25 17.7863 11.4187 18.0746 11.8687 17.9824C13.6554 17.3639 15.2079 16.1866 16.3077 14.6161C17.4076 13.0456 17.9995 11.1611 18 9.22764C18 4.12937 13.9725 0 9 0Z'
                          fill='#F9F9F9'
                        />
                      </svg>
                    </a>
                  </div>
                  {/* <span className='students-winners__expiration-date'>Окончил курс 03/08/21</span> */}
                </div>
              </div>
              <span className='students-winners__gift'>Получил стажировку</span>
              <p className='students-winners__feedback' id='feedback-3'>
                “Мне нравится в курсах что непонятные темы объясняются очень просто, нет слишком
                много заезжанных слов и всё это подкрепляется практикой. Обучение проходит интересно
                и менторы детально разбирают каждый твой вопрос”
              </p>
              <button
                className='students-winners__show-all'
                onClick={() => {
                  setShowAll3(!showAll3);
                  document
                    .getElementById('feedback-3')
                    .classList.toggle('students-winners__feedback--show-all');
                }}>
                {showAll3 ? 'Свернуть' : 'Показать полностью'}
              </button>
            </div>
          </SplideSlide>
        </Splide>
      </div>
    </section>
  );
};

export default StudentsWinners;
