import Moment from 'react-moment';
import { HashLink as Link } from 'react-router-hash-link';

const ShortInfo = ({ openModal, startCourseDate }) => {
  return (
    <section className='short-info' data-aos='fade-in'>
      <div className='short-info__container'>
        <ul className='short-info__list'>
          <li className='short-info__item'>
            <h3 className='short-info__title'>Старт обучения</h3>
            {startCourseDate ?
              <span className='short-info__description'>
                <Moment locale='ru' format='D MMMM'>
                  {startCourseDate}
                </Moment>
              </span> : ""
            }
            <Link
              className='short-info__button'
              to='/#send-request'
              smooth>
              Оставить заявку
            </Link>
            {/* <button
              className=''
              onClick={() => {
                openModal();
                document.documentElement.classList.add('scroll-off');
              }}
            >
              Оставить заявку
            </button> */}
          </li>
          <li className='short-info__item'>
            <h3 className='short-info__title'>Длительность обучения</h3>
            <span className='short-info__description'>4 недели интенсивно, или по вашему графику</span>
          </li>
          <li className='short-info__item'>
            <h3 className='short-info__title'>Формат</h3>
            <span className='short-info__description'>Онлайн с ментором и гибким графиком</span>
          </li>
          <li className='short-info__item'>
            <h3 className='short-info__title'>Уровень сложности</h3>
            <span className='short-info__description'>
              Курс можно проходить без опыта программирования
            </span>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default ShortInfo;
