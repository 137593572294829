import { Link } from 'react-router-dom';
import { Link as AnimateLink } from 'react-scroll';

const Footer = ({ openModal }) => {
  return (
    <footer className='page-footer' data-aos='fade-in'>
      <div className='page-footer__container'>
        <div className='page-footer__card'>
          <div className='page-footer__copyright-wrapper'>
            <AnimateLink
              className='page-footer__logo-link'
              to='main-header'
              smooth={true}
              duration={500}>
              <div className='page-footer__logo-wrapper'>
                <img
                  className='page-footer__logo'
                  src={require('../assets/images/logo.svg').default}
                  alt=''
                />
                <span className='main-header__company-name'>Code Union</span>
              </div>
            </AnimateLink>

            <p className='page-footer__copyright'>
              Copyright 2023© Code Union <br /> Все права защищены
            </p>
          </div>
          <div className='page-footer__pages-wrapper'>
            <h3 className='page-footer__title'>Страницы</h3>
            <ul className='page-footer__pages-list'>
              <li className='page-footer__page-item'>
                <a
                  className='page-footer__page-link'
                  href='https://docs.google.com/document/d/1-Xmkt8CmcUr8qcC89jqDsnHDsm4ehrLCvjWib-1Uh8Q/edit?usp=sharing'
                  rel='noreferrer'
                  target='_blank'>
                  Договор публичной оферты
                </a>
              </li>
              <li className='page-footer__page-item'>
                <a
                  className='page-footer__page-link'
                  href='https://docs.google.com/document/d/12SRFPLFVPrTEH7Xo8aBIaWBpyJ1v-jFCV2HKdM-WR8s/edit?usp=sharing'
                  rel='noreferrer'
                  target='_blank'>
                  Политика конфиденциальности
                </a>
              </li>
              <li className='page-footer__page-item'>
                <button
                  className='page-footer__page-link'
                  onClick={() => {
                    openModal()
                    document.documentElement.classList.add('scroll-off');
                  }}>
                  Записаться на курс
                </button>
              </li>
              <li className='page-footer__page-item'>
                <a
                  className='page-footer__page-link'
                  href='https://mobile.codeunion.kz/webinar'
                  rel='noreferrer'
                  target='_blank'>
                  Записаться на вебинар
                </a>
              </li>
            </ul>
          </div>
          <div className='page-footer__contacts-wrapper'>
            <h3 className='page-footer__title'>Свяжитесь с нами</h3>
            <ul className='page-footer__contacts'>
              {/* <li className='page-footer__contacts-item'>
                <a
                  className='page-footer__contacts-link'
                  href='https://codeunion.kz'
                  rel='noreferrer'
                  target='_blank'>
                  <svg
                    width='25'
                    height='25'
                    viewBox='0 0 25 25'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M12.5 0C5.6075 0 0 5.6075 0 12.5C0 19.3925 5.6075 25 12.5 25C19.3925 25 25 19.3925 25 12.5C25 5.6075 19.3925 0 12.5 0ZM2.5 12.5C2.5 11.3763 2.695 10.2975 3.03875 9.28875L5 11.25L7.5 13.75V16.25L10 18.75L11.25 20V22.4137C6.32625 21.795 2.5 17.59 2.5 12.5ZM20.4125 18.5912C19.5963 17.9337 18.3587 17.5 17.5 17.5V16.25C17.5 15.587 17.2366 14.9511 16.7678 14.4822C16.2989 14.0134 15.663 13.75 15 13.75H10V10C10.663 10 11.2989 9.73661 11.7678 9.26777C12.2366 8.79893 12.5 8.16304 12.5 7.5V6.25H13.75C14.413 6.25 15.0489 5.98661 15.5178 5.51777C15.9866 5.04893 16.25 4.41304 16.25 3.75V3.23625C19.91 4.7225 22.5 8.3125 22.5 12.5C22.4998 14.7059 21.7653 16.8489 20.4125 18.5912Z'
                      fill='#8B8B8B'
                    />
                  </svg>
                </a>
              </li> */}
              <li className='page-footer__contacts-item'>
                <a className='page-footer__contacts-link' href='tel:+77774183462'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path xmlns="http://www.w3.org/2000/svg" d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z" stroke="#8B8B8B" stroke-width="2" stroke-miterlimit="0" />
                  </svg>
                </a>
              </li>
              <li className='page-footer__contacts-item'>
                <a className='page-footer__contacts-link' href='tel:+77471848416'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path xmlns="http://www.w3.org/2000/svg" d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z" stroke="#8B8B8B" stroke-width="2" stroke-miterlimit="0" />

                  </svg>
                </a>
              </li>
              <li className='page-footer__contacts-item'>
                <a className='page-footer__contacts-link' href='mailto:courses@codeunion.kz'>
                  <svg
                    width='27'
                    height='21'
                    viewBox='0 0 27 21'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M13.5 9.79438L4.96774 3.16172H22.0323L13.5 9.79438ZM3.15893 18.4839V4.39753L12.4453 11.6179L12.4454 11.6179C12.7458 11.8513 13.1154 11.978 13.4958 11.978C13.8762 11.978 14.2458 11.8513 14.5463 11.6179L23.8411 4.39797V18.4839H3.15893ZM25.1071 0.85H1.89286C1.31615 0.85 0.85 1.31615 0.85 1.89286V19.75C0.85 20.3267 1.31615 20.7929 1.89286 20.7929H25.1071C25.6838 20.7929 26.15 20.3267 26.15 19.75V1.89286C26.15 1.31615 25.6838 0.85 25.1071 0.85Z'
                      fill='#8B8B8B'
                      stroke='#8B8B8B'
                      strokeWidth='0.3'
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
