import moment from 'moment';
import { useState } from 'react';
import MaskInput from './maskInput';
// import {Link} from 'react-router-dom';

const ModalForm = ({ discountEndDate, closeModal, selectProgram, setModalPrice }) => {
  MaskInput();

  const [paymentMethod, setPaymentMethod] = useState(1);

  const getModalPriceValue = () => {
    if (selectProgram === 'Вводный') {
      return setModalPrice();
    } else if (selectProgram === 'Вёрстка') {
      return setModalPrice().finalPriceStage2;
    } else if (selectProgram === 'Логика') {
      return setModalPrice().finalPriceStage3;
    }
  };

  const getModalPrice = () => {
    if (selectProgram === 'Вводный') {
      return setModalPrice();
    } else if (selectProgram === 'Вёрстка') {
      if (paymentMethod === 1) {
        return setModalPrice().price2;
      } else if (paymentMethod === 2) {
        return setModalPrice().creditPrice2;
      }
    } else if (selectProgram === 'Логика') {
      if (paymentMethod === 1) {
        return setModalPrice().price3;
      } else if (paymentMethod === 2) {
        return setModalPrice().creditPrice3;
      }
    }
  };

  const getModalPriceNew = () => {
    if (selectProgram === 'Вводный') {
      return setModalPrice();
    } else if (selectProgram === 'Вёрстка') {
      if (paymentMethod === 1) {
        return setModalPrice().finalPriceStage2;
      } else if (paymentMethod === 2) {
        return setModalPrice().creditPriceWithDiscount2;
      }
    } else if (selectProgram === 'Логика') {
      if (paymentMethod === 1) {
        return setModalPrice().finalPriceStage3;
      } else if (paymentMethod === 2) {
        return setModalPrice().creditPriceWithDiscount3;
      }
    }
  };

  const [checked, setChecked] = useState(false);
  const showEntityInput = () => {
    setChecked(!checked);
  };

  const sendStatusMessages = {
    default: '',
    loading: 'Отправка',
    failure: 'Ошибка отправки',
    success: 'Заявка успешно отправлена! Мы с вами свяжемся в ближайшее время!'
  };

  const [sendStatus, setSendStatus] = useState(sendStatusMessages.default);
  const [successSubmit, setSuccessSubmit] = useState(false);

  const sendFormRequest = submitButtonId => async e => {
    e.preventDefault();
    setSendStatus(sendStatusMessages.loading);
    const form = e.target;
    const submitButton = document.getElementById(submitButtonId);
    submitButton.disabled = true;
    let productRows = [305, 307, 323];
    let productRow = 323;
    var companyId;
    var contactId;

    if (selectProgram == 'Вводный') {
      productRow = productRows[0];
    } else if (selectProgram == 'Вёрстка') {
      productRow = productRows[1];
    } else if (selectProgram == 'Логика') {
      productRow = productRows[2];
    }

    if (checked) {
      let res = await fetch('https://codeunion.bitrix24.kz/rest/7/2sswdteh44qupdpa/crm.company.add.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf8'
        },
        body: JSON.stringify({
          FIELDS: {
            "TITLE": form.companyName.value,
            "COMPANY_TYPE": "CUSTOMER",
            "CURRENCY_ID": "KZT",
            // "EMAIL": [{ "VALUE": form.email.value }],
            "PHONE": [{ "VALUE": form.phoneNumber.value }]
          }
        })
      });

      let data = await res.json();
      companyId = data.result;
    } else {
      let res = await fetch('https://codeunion.bitrix24.kz/rest/7/2sswdteh44qupdpa/crm.contact.add.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf8'
        },
        body: JSON.stringify({
          FIELDS: {
            "NAME": form.name.value,
            "TYPE_ID": "CLIENT",
            // "EMAIL": [{ "VALUE": form.email.value }],
            "PHONE": [{ "VALUE": form.phoneNumber.value }]
          }
        })
      });

      let data = await res.json();
      contactId = data.result;
    }

    try {
      let res = await fetch('https://codeunion.bitrix24.kz/rest/7/2sswdteh44qupdpa/crm.deal.add.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf8'
        },
        body: JSON.stringify({
          FIELDS: {
            TITLE: `${form.name.value}`,
            OPPORTUNITY: form.coursePrice.value,
            CURRENCY_ID: 'KZT',
            CATEGORY_ID: 3,
            UF_CRM_1630754788684: false,
            COMPANY_ID: companyId,
            CONTACT_ID: contactId,
            UTM_SOURCE: "main_mobile_site",
            UF_CRM_1635847865287: form?.instagram?.value,
            UF_CRM_1630754820481: form.courseStage.value,
            UF_CRM_1675232719592: paymentMethod == 1 ? null : 235,
          }
        })
      });

      let body = await res.json();

      let productRes = await fetch('https://codeunion.bitrix24.kz/rest/7/2sswdteh44qupdpa/crm.deal.productrows.set', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf8'
        },
        body: JSON.stringify({
          "id": body.result,
          "rows": [{ "PRODUCT_ID": productRow, "PRICE": form.coursePrice.value }]
        })
      });

      if (productRes.status >= 400) {
        setSendStatus(sendStatusMessages.failure);
      } else {
        setSuccessSubmit(true);
      }
    } catch (error) {
      console.log(error);
      setSendStatus(sendStatusMessages.failure);
    }
  };

  return (
    <section className='modal modal-form'>
      <div
        className='modal__background'
        onClick={() => {
          closeModal();
          document.documentElement.classList.remove('scroll-off');
        }}
      />
      <div className='modal__wrapper modal-form__wrapper'>
        <div className='modal__container modal-form__container'>
          {successSubmit === false ? (
            <>
              <div className='modal__title-wrapper modal-form__title-wrapper'>
                <h2 className='modal__title modal-form__title section-title'>
                  Стань разработчиком
                </h2>
                <button
                  className='modal__close-button modal-courses__close-button'
                  onClick={() => {
                    closeModal();
                    document.documentElement.classList.remove('scroll-off');
                  }}
                />
              </div>
              <p className='modal-form__description'>
                Мы поможем вам стать разработчиком и трудоустроится за 3 недели
              </p>
              <form className='modal-form__form' onSubmit={sendFormRequest('submit-button-modal')}>
                <div className='modal-form__rate-wrapper'>
                  <input
                    className='visually-hidden'
                    type='text'
                    name='courseStage'
                    value={selectProgram}
                    readOnly
                  />
                  <span className='modal-form__course-name'>Flutter курс</span>
                  <input
                    className='visually-hidden'
                    type='text'
                    name='coursePrice'
                    readOnly
                    value={getModalPriceValue()}
                  />
                  {/* <span className='modal-form__course-price'>
                    {moment().isAfter(discountEndDate) || selectProgram === 'Вводный' ? null : (
                      <span className='modal-form__old-price'>{`${getModalPrice().toLocaleString(
                        'ru-RU'
                      )} ₸`}</span>
                    )}
                    <span className='modal-form__current-price-wrapper'>
                      <span className='modal-form__current-price'>
                        {moment().isAfter(discountEndDate)
                          ? `${getModalPrice().toLocaleString('ru-RU')} ₸`
                          : `${getModalPriceNew().toLocaleString('ru-RU')} ₸`}
                      </span>
                      {paymentMethod === 2 ? (
                        <span className='modal-form__credit-term'>на 3 месяца</span>
                      ) : null}
                    </span>
                  </span> */}
                </div>
                {/* <div className='modal-form__payment-method-wrapper'>
                  <label>
                    <div className='modal-form__label-wrapper modal-form__label-wrapper--1'>
                      <input
                        className='modal-form__radio-input visually-hidden'
                        type='radio'
                        name='paymentMethod'
                        value='Разовая оплата'
                        defaultChecked
                        onChange={() => {
                          setPaymentMethod(1);
                        }}
                      />
                      <span className='modal-form__radio' />
                      <span className='modal-form__label'>Разовая оплата</span>
                    </div>
                  </label>
                  {selectProgram !== 'Вводный' ? (
                    <label>
                      <div className='modal-form__label-wrapper'>
                        <input
                          className='modal-form__radio-input visually-hidden'
                          type='radio'
                          name='paymentMethod'
                          value='Оплата в рассрочку'
                          onChange={() => {
                            setPaymentMethod(2);
                          }}
                        />
                        <span className='modal-form__radio' />
                        <span className='modal-form__label'>В рассрочку</span>
                      </div>
                    </label>
                  ) : null}
                </div> */}
                <div className='modal-form__input-group'>
                  <input
                    className='modal-form__text-input'
                    type='text'
                    placeholder='Ваше имя*'
                    required
                    name='name'
                  />
                  <input
                    type='tel'
                    className='modal-form__text-input'
                    id='phone-input'
                    data-tel-input
                    minlength='17'
                    maxlength='18'
                    name='phoneNumber'
                    placeholder='Номер телефона*'
                    required
                  />
                  {/* <input
                    className='modal-form__text-input'
                    type='email'
                    placeholder='Email*'
                    required
                    name='email'
                  />
                  <input
                    className='modal-form__text-input'
                    type='link'
                    placeholder='Ссылка на инстаграм'
                    name='instagram'
                  /> */}
                </div>
                <div className='modal-form__checkbox-wrapper'>
                  <label>
                    <input
                      className='modal-form__checkbox-input visually-hidden'
                      type='checkbox'
                      checked={checked}
                      onChange={() => showEntityInput()}
                      name='entity'
                      value='Юр. лицо'
                    />
                    <span className='modal-form__checkbox' />
                    <span className='modal-form__entity-label'>Я юридическое лицо</span>
                  </label>
                  {checked === true ? (
                    <input
                      className='modal-form__text-input modal-form__entity-input'
                      type='text'
                      name='companyName'
                      placeholder='Название компании'
                    />
                  ) : null}
                </div>
                <div className='modal-form__button-wrapper'>
                  <p className='modal-form__send-status'>{sendStatus}</p>
                  <button
                    className='modal-form__submit-button'
                    id='submit-button-modal'
                    type='submit'>
                    Записаться на курс
                  </button>
                  {/* <p className='modal-form__agreement'>
                    Нажимая кнопку “Записаться на курс” вы соглашаетесь{' '}
                    <Link
                      className='modal-form__agreement-link'
                      to='/privacy-policy'
                      onClick={() => {
                        closeModal();
                        document.documentElement.classList.remove('scroll-off');
                      }}>
                      с офертой
                    </Link>{' '}
                    и{' '}
                    <Link
                      className='modal-form__agreement-link'
                      to='/privacy-policy'
                      onClick={() => {
                        closeModal();
                        document.documentElement.classList.remove('scroll-off');
                      }}>
                      политикой конфиденциальности
                    </Link>
                  </p> */}
                </div>
              </form>
            </>
          ) : null}
          {successSubmit ? (
            <div className='success-submit__container'>
              <h2 className='success-submit__title'>Заявка отправлена!</h2>
              <img className='success-submit__image' src={require('../assets/images/confirm-email.png').default} />
              <button
                className='success-submit__go-back-button'
                onClick={() => {
                  closeModal();
                  document.documentElement.classList.remove('scroll-off');
                }}>
                Перейти к сайту
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default ModalForm;
