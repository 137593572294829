const Results = () => {
  return (
    <section className='results' data-aos='fade-up'>
      <div className='results__container'>
        <h2 className='results__title section-title'>Что после окончания курса?</h2>
        <p className='results__list-description'>
          По результатам проделанной работы, студенты могут получить:
        </p>
        <ul className='results__main-gifts'>
          <li className='results__main-gift-item'>Оплачиваемая стажировка в Code Union</li>
          <li className='results__main-gift-item'>
            Помощь в трудоустройстве
          </li>
        </ul>
        <p className='results__list-description'>Каждый студент получает:</p>
        <ul className='results__list'>
          <li className='results__item'>
            <h3 className='results__item-title'>Сертификат</h3>
            <p className='results__item-description'>Подтверждающий прохождение курса</p>
          </li>
          <li className='results__item'>
            <h3 className='results__item-title'>Доступ к курсу</h3>
            <p className='results__item-description'>Неограниченный доступ к материалам, которые регулярно обновляются</p>
          </li>
          <li className='results__item'>
            <h3 className='results__item-title'>Коммьюнити</h3>
            <p className='results__item-description'>Чат со всеми студентами и менторами</p>
          </li>
          <li className='results__item'>
            <h3 className='results__item-title'>Поддержка</h3>
            <p className='results__item-description'>Помощь, даже после окончания курсов</p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Results;
