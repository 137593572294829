import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/ru';
import { HashLink as Link } from 'react-router-hash-link';


const Promo = ({ startCourseDate, discountLastDay, discountEndDate, openModal, discountPercent }) => {
  return (
    <section className='promo' id='promo' data-aos='fade-in'>
      <div className='promo__container'>
        <div className='promo__start-info'>
          <p className='promo__start-course'>
            Старт курса{' '}
            <span className='promo__start-date'>
              {startCourseDate ?
                <Moment locale='ru' format='D MMMM'>
                  {startCourseDate}
                </Moment>
                : ' каждый год'
              }
            </span>
          </p>
          {moment().isAfter(discountEndDate) ? null : (
            <span className='promo__discount'>
              Скидка {discountPercent}% до{' '}
              <Moment locale='ru' format='D MMMM'>
                {discountLastDay}
              </Moment>
            </span>
          )}
        </div>
        <b className='promo__message'>Войди в мобильную разработку на Flutter</b>
        <p className='promo__cta'>
          Flutter - это революционный фреймворк от Google, предоставляющий простой и эффективный способ создания красивых и высокопроизводительных мобильных приложений для Android и iOS одновременно {' '}
        </p>
        {/* <Link
          className='promo__button white-button'
          to='/#send-request'
          onClick={
            window.ym(92315430,'reachGoal','offerBtn')
          }
          smooth>
          Стать разработчиком
        </Link> */}
        <button
          className='promo__button white-button'
          onClick={() => {
            window.ym(92315430,'reachGoal','offerBtn')
            openModal();
            document.documentElement.classList.toggle('scroll-off');
          }}>
          Стать разработчиком
        </button>
      </div>
      <div className='promo__background'>
        <div className='promo__background-wrapper'>
          <div className='promo__bg-part promo__bg-blur' />
          <div className='promo__bg-part promo__bg-gift-1' />
          <div className='promo__bg-part promo__bg-hand' />
          <div className='promo__bg-part promo__bg-gift-2' />
          <div className='promo__bg-part promo__bg-colored-shadow' />
        </div>
      </div>
    </section>
  );
};

export default Promo;
