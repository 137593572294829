const LearningProcess = () => {
  return (
    <section className='learning-process' data-aos='fade-up'>
      <div className='learning-process__container'>
        <h2 className='learning-process__title section-title'>Как проходит обучение?</h2>
        <ul className='learning-process__step-list'>
          <li className='learning-process__step-item'>
            <h3 className='learning-process__step-title'>1. Изучение материала</h3>
            <p className='learning-process__step-description'>
              После прохождения урока, полученные знания применяются на практике и вы пошагово
              разрабатываете проект.
            </p>
          </li>
          <li className='learning-process__step-item'>
            <h3 className='learning-process__step-title'>2. Проверка кода</h3>
            <p className='learning-process__step-description'>
              Выполняя практические задания, менторы проверяют его и дают обратную связь. После
              исправления, вы переходите к следующей теме.
            </p>
          </li>
          <li className='learning-process__step-item'>
            <h3 className='learning-process__step-title'>3. Результаты курса</h3>
            <p className='learning-process__step-description'>
              Самые заинтересованные и хорошо проявившие себя студенты получают оплачиваемую
              стажировку у нас или наших партнеров.
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default LearningProcess;
