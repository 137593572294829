const Technologies = () => {
  return (
    <section className='technologies' data-aos='fade-up'>
      <div className='technologies__container'>
        <h2 className='technologies__title section-title'>Стэк технологий</h2>
        <ul className='technologies__list'>
          <li className='technologies__item technologies__item--flutter'>Flutter</li>
          <li className='technologies__item technologies__item--dart'>Dart</li>
          <li className='technologies__item technologies__item--vscode'>Visual Studio Code</li>
          <li className='technologies__item technologies__item--postman'>Postman</li>
          <li className='technologies__item technologies__item--figma'>Figma</li>
          <li className='technologies__item technologies__item--android-studio'>Android Studio</li>
          <li className='technologies__item technologies__item--xcode'>Xcode</li>
        </ul>
      </div>
    </section>
  );
};

export default Technologies;
