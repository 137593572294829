import {useState} from 'react';
import Menu from './Menu';
import {HashLink} from 'react-router-hash-link';

const Header = ({openModal}) => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const toggleMenu = () => setMenuVisible(!isMenuVisible);

  return (
    <header className='main-header' id='main-header'>
      <div className='main-header__container'>
        <HashLink className='main-header__logo-link' to='/#main-header' smooth>
          <div className='main-header__logo-wrapper'>
            <img
              data-aos='fade-in'
              className='main-header__logo'
              src={require('../assets/images/logo.svg').default}
              alt=''
            />
            <span className='main-header__company-name'>Code Union</span>
          </div>
        </HashLink>
        <button
          className={`menu__button ${isMenuVisible ? 'menu__button--close' : ''}`}
          onClick={() => {
            toggleMenu();
            document.documentElement.classList.toggle('scroll-off');
          }}
        />
        <Menu
          data-aos='fade-in'
          onClick={toggleMenu}
          openModal={openModal}
          isMenuVisible={isMenuVisible}
        />
      </div>
    </header>
  );
};

export default Header;
