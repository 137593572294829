import Collapsible from 'react-collapsible';
import { HashLink as Link } from 'react-router-hash-link';

const CourseProgram = ({ price1, price2, price3, openModal, setSelectProgram }) => {
  return (
    <section className='course-program' id='course-program'>
      <div className='course-program__container'>
        <h2 className='course-program__title section-title'>Этапы курсов</h2>
        <div className='course-program__block' data-aos='fade-up'>
          <div className='course-program__about-block course-program__about-block--1'>
            <div className='course-program__about-block-wrapper course-program__about-block-wrapper--1'>
              <span className='course-program__block-name'>
                <span className='course-program__block-title'>Вводный</span> • 4 блока • 15 уроков
              </span>
              <p className='course-program__block-description'>
                Почему стоит переходить на Flutter, какие у него преимущество и как сделать первый
                запуск приложения”.
              </p>
            </div>
            <div className='course-program__button-wrapper'>
              <Link
                className='course-program__sign-up-button'
                to='/#send-request'
              >
                Записаться
              </Link>
              {/* <span className='course-program__block-price'>{`${price1.toLocaleString(
                'ru-RU'
              )} ₸`}</span> */}
            </div>
          </div>
          <div className='course-program__accordion-wrapper'>
            <Collapsible
              transitionTime={300}
              classParentString='course-program__accordion Collapsible'
              trigger='Знакомство'>
              <span className='course-program__lessons-counter'>3 урока</span>
              <ul className='course-program__lessons-list'>
                <li className='course-program__lesson'>Code Union</li>
                <li className='course-program__lesson'>Рекомендации</li>
                <li className='course-program__lesson'>Как проходит курс?</li>
              </ul>
            </Collapsible>
          </div>
          <div className='course-program__accordion-wrapper'>
            <Collapsible
              transitionTime={300}
              classParentString='course-program__accordion Collapsible'
              trigger='Введение'>
              <span className='course-program__lessons-counter'>3 урока</span>
              <ul className='course-program__lessons-list'>
                <li className='course-program__lesson'>Flutter и преимущества</li>
                <li className='course-program__lesson'>Перспективы</li>
                <li className='course-program__lesson'>Текущая ситуация на рынке</li>
              </ul>
            </Collapsible>
          </div>
          <div className='course-program__accordion-wrapper'>
            <Collapsible
              transitionTime={300}
              classParentString='course-program__accordion Collapsible'
              trigger='Установка и настройка среды'>
              <span className='course-program__lessons-counter'>5 уроков</span>
              <ul className='course-program__lessons-list'>
                <li className='course-program__lesson'>Установка Flutter</li>
                <li className='course-program__lesson'>Установка Visual Studio Code</li>
                <li className='course-program__lesson'>Эмуляторы iOS и Android</li>
                <li className='course-program__lesson'>Установка Git</li>
                <li className='course-program__lesson'>Установка Postman</li>
              </ul>
            </Collapsible>
          </div>
          <div className='course-program__accordion-wrapper'>
            <Collapsible
              transitionTime={300}
              classParentString='course-program__accordion Collapsible'
              trigger='Запуск первого приложения'>
              <span className='course-program__lessons-counter'>4 урока</span>
              <ul className='course-program__lessons-list'>
                <li className='course-program__lesson'>Создание проекта и запуск</li>
                <li className='course-program__lesson'>Разбор стандартного приложения</li>
                <li className='course-program__lesson'>Изучение синтаксиса</li>
                <li className='course-program__lesson'>Рефлексия</li>
              </ul>
            </Collapsible>
          </div>
        </div>
        <div className='course-program__block' data-aos='fade-up'>
          <div className='course-program__about-block course-program__about-block--2'>
            <div className='course-program__about-block-wrapper course-program__about-block-wrapper--2'>
              <span className='course-program__block-name'>
                <span className='course-program__block-title'>Вёрстка</span> • 9 блоков • 33 урока
              </span>
              <p className='course-program__block-description'>
                Базовые понятия принципов программирования, верстка по дизайну и настройка роутинга
                скринов
              </p>
            </div>
            <div className='course-program__button-wrapper'>
              <Link
                className='course-program__sign-up-button'
                to='/#send-request'
                >
                Записаться
              </Link>
              {/* <span className='course-program__block-price'>{`${price2.toLocaleString(
                'ru-RU'
              )} ₸`}</span> */}
            </div>
          </div>
          <p className='course-program__include-blocks'>
            <span>В стоимость входят блоки из прошлого этапа</span>
          </p>
          <div className='course-program__accordion-wrapper'>
            <Collapsible
              transitionTime={300}
              classParentString='course-program__accordion Collapsible'
              trigger='Git'>
              <span className='course-program__lessons-counter'>2 урока</span>
              <ul className='course-program__lessons-list'>
                <li className='course-program__lesson'>Основные команды</li>
                <li className='course-program__lesson'>Git flow</li>
              </ul>
            </Collapsible>
          </div>
          <div className='course-program__accordion-wrapper'>
            <Collapsible
              transitionTime={300}
              classParentString='course-program__accordion Collapsible'
              trigger='Концепция программирования'>
              <span className='course-program__lessons-counter'>6 уроков</span>
              <ul className='course-program__lessons-list'>
                <li className='course-program__lesson'>Структура папок</li>
                <li className='course-program__lesson'>KISS, DRY, SOLID</li>
                <li className='course-program__lesson'>Конвенция имён</li>
                <li className='course-program__lesson'>Наследование</li>
                <li className='course-program__lesson'>Абстрактные классы</li>
                <li className='course-program__lesson'>Реализация интерфейсов</li>
              </ul>
            </Collapsible>
          </div>
          <div className='course-program__accordion-wrapper'>
            <Collapsible
              transitionTime={300}
              classParentString='course-program__accordion Collapsible'
              trigger='Введение в проект'>
              <span className='course-program__lessons-counter'>6 уроков</span>
              <ul className='course-program__lessons-list'>
                <li className='course-program__lesson'>Подготовка репозитория</li>
                <li className='course-program__lesson'>Дизайн системы</li>
                <li className='course-program__lesson'>Разбор дизайна и структуры</li>
                <li className='course-program__lesson'>Разбор вёрстки страницы</li>
                <li className='course-program__lesson'>Меняем вид кнопок</li>
                <li className='course-program__lesson'>Конечный вид авторизации</li>
              </ul>
            </Collapsible>
          </div>
          <div className='course-program__accordion-wrapper'>
            <Collapsible
              transitionTime={300}
              classParentString='course-program__accordion Collapsible'
              trigger='Routing'>
              <span className='course-program__lessons-counter'>3 урока</span>
              <ul className='course-program__lessons-list'>
                <li className='course-program__lesson'>Вёрстка второй страницы</li>
                <li className='course-program__lesson'>Навигация</li>
                <li className='course-program__lesson'>Философия виджетов</li>
              </ul>
            </Collapsible>
          </div>
          <div className='course-program__accordion-wrapper'>
            <Collapsible
              transitionTime={300}
              classParentString='course-program__accordion Collapsible'
              trigger='Начало проекта'>
              <span className='course-program__lessons-counter'>1 урок</span>
              <ul className='course-program__lessons-list'>
                <li className='course-program__lesson'>Вёрстка остальных страниц</li>
              </ul>
            </Collapsible>
          </div>
        </div>
        <div className='course-program__block' data-aos='fade-up'>
          <div className='course-program__about-block course-program__about-block--3'>
            <div className='course-program__about-block-wrapper course-program__about-block-wrapper--3'>
              <span className='course-program__block-name'>
                <span className='course-program__block-title'>Логика</span> • 5 блоков • 15 уроков
              </span>
              <p className='course-program__block-description'>
                Архитектура приложения, разделение логики от интерфейса, работа с серверами и
                интеграция с API
              </p>
            </div>
            <div className='course-program__button-wrapper'>
              <Link
                className='course-program__sign-up-button'
                to='/#send-request'>
                Записаться
              </Link>
              {/* <span className='course-program__block-price'>{`${price3.toLocaleString(
                'ru-RU'
              )} ₸`}</span> */}
            </div>
          </div>
          <p className='course-program__include-blocks'>
            <span>В стоимость входят блоки из прошлого этапа</span>
          </p>
          <div className='course-program__accordion-wrapper'>
            <Collapsible
              transitionTime={300}
              classParentString='course-program__accordion Collapsible'
              trigger='Интеграция с API'>
              <span className='course-program__lessons-counter'>8 уроков</span>
              <ul className='course-program__lessons-list'>
                <li className='course-program__lesson'>Как работают сети</li>
                <li className='course-program__lesson'>JSON, HTTP</li>
                <li className='course-program__lesson'>REST API</li>
                <li className='course-program__lesson'>Dio</li>
                <li className='course-program__lesson'>Аутентификация</li>
                <li className='course-program__lesson'>Local Storage</li>
                <li className='course-program__lesson'>Модели</li>
                <li className='course-program__lesson'>Json Serializable</li>
              </ul>
            </Collapsible>
          </div>
          <div className='course-program__accordion-wrapper'>
            <Collapsible
              transitionTime={300}
              classParentString='course-program__accordion Collapsible'
              trigger='BLoC'>
              <span className='course-program__lessons-counter'>3 урока</span>
              <ul className='course-program__lessons-list'>
                <li className='course-program__lesson'>Реактивная разработка</li>
                <li className='course-program__lesson'>BLoC Авторизации</li>
                <li className='course-program__lesson'>Изменение стейтов в UI</li>
              </ul>
            </Collapsible>
          </div>
          <div className='course-program__accordion-wrapper'>
            <Collapsible
              transitionTime={300}
              classParentString='course-program__accordion Collapsible'
              trigger='Dependency Injection'>
              <span className='course-program__lessons-counter'>2 урока</span>
              <ul className='course-program__lessons-list'>
                <li className='course-program__lesson'>Что такое DI</li>
                <li className='course-program__lesson'>GetIt</li>
              </ul>
            </Collapsible>
          </div>
          <div className='course-program__accordion-wrapper'>
            <Collapsible
              transitionTime={300}
              classParentString='course-program__accordion Collapsible'
              trigger='Результаты'>
              <span className='course-program__lessons-counter'>1 урок</span>
              <ul className='course-program__lessons-list'>
                <li className='course-program__lesson'>Конечный результат</li>
              </ul>
            </Collapsible>
          </div>
          <div className='course-program__accordion-wrapper'>
            <Collapsible
              transitionTime={300}
              classParentString='course-program__accordion Collapsible'
              trigger='App Store & Play Market'>
              <span className='course-program__lessons-counter'>1 урок</span>
              <ul className='course-program__lessons-list'>
                <li className='course-program__lesson'>Публикация приложений в сторы</li>
              </ul>
            </Collapsible>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CourseProgram;
