const AboutCourse = () => {
  return (
    <section className='about-course' data-aos='fade-up'>
      <div className='about-course__container'>
        <h2 className='about-course__title section-title'>О курсах</h2>
        <div className='about-course__wrapper'>
          <div className='about-course__info-container'>
            <div className='about-course__author-block'>
              <picture>
                <source
                  media='(min-width: 1200px)'
                  srcSet={`${require('../assets/images/mentor-1-desktop@1x.png')} 1x,
                  ${require('../assets/images/mentor-1-desktop@2x.png')} 2x`}
                />
                <img
                  src={require('../assets/images/mentor-1-mobile@1x.png')}
                  srcSet={`${require('../assets/images/mentor-1-mobile@2x.png')} 2x`}
                  alt=''
                  className='about-course__author-photo'
                />
              </picture>
              <div className='about-course__author-info'>
                <span className='about-course__author-name'>Чингиз Марипбеков</span>
                <span className='about-course__author'>Методолог курса</span>
              </div>
            </div>
            <div className='about-course__info-block'>
              <h3 className='about-course__info-title'>Цель курсов</h3>
              <p className='about-course__info-text'>
              Мы хотим вдохновить вас успешными приложениями, разработанными с помощью Flutter и помочь освоить этот инструмент став успешным мобильным разработчиком.
              </p>
            </div>
          </div>
          <div className='about-course__info-container'>
            <div className='about-course__info-block'>
              <h3 className='about-course__info-title'>От создателей курса</h3>
              <p className='about-course__info-text'>
                Уже как 4 года мы пишем на Flutter и делимся своим опытом чтобы
                увеличить количество крутых разработчиков в Казахстане. За 3 недели вы освоите все
                необходимые технологии, которые используются в продуктовых компаниях и применяются в
                реальных проектах. Вместо разработки нескольких проектов, мы даём навык с помощью
                которого вы сможете реализовать любое приложение самостоятельно.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutCourse;
