import { HashLink as Link } from 'react-router-hash-link';

const ModalCourses = ({ closeModal, price1, price2, price3, setSelectProgram, openModalForm }) => {
  return (
    <section className='modal modal-courses'>
      <div
        className='modal__background'
        onClick={() => {
          closeModal();
          document.documentElement.classList.remove('scroll-off');
        }}
      />
      <div className='modal__wrapper modal-courses__wrapper'>
        <div className='modal__container modal-courses__container'>
          <div className='modal__title-wrapper modal-courses__title-wrapper'>
            <h2 className='modal__title modal-courses__title section-title'>Выберите этап курса</h2>
            <button
              className='modal__close-button modal-courses__close-button'
              onClick={() => {
                closeModal();
                document.documentElement.classList.remove('scroll-off');
              }}
            />
          </div>
          <ul className='modal-courses__stages-list'>
            <li className='modal-courses__about-block modal-courses__about-block--1'>
              <div className='modal-courses__about-block-wrapper modal-courses__about-block-wrapper--1'>
                <span className='modal-courses__block-name'>Вводный</span>
                <p className='modal-courses__block-description'>
                  Почему стоит переходить на Flutter, какие у него преимущество и как сделать первый
                  запуск приложения.
                </p>
              </div>
              <div className='modal-courses__button-wrapper'>
                <button
                  className='modal-courses__sign-up-button'
                  onClick={() => {
                    closeModal();
                    openModalForm();
                    setSelectProgram('Логика');
                  }}>
                  Записаться
                </button>
                {/* <span className='modal-courses__block-price'>{`${price1.toLocaleString(
                  'ru-RU'
                )} ₸`}</span> */}
              </div>
            </li>
            <li className='modal-courses__about-block modal-courses__about-block--2'>
              <div className='modal-courses__about-block-wrapper modal-courses__about-block-wrapper--2'>
                <span className='modal-courses__block-name'>Вёрстка</span>
                <p className='modal-courses__block-description'>
                  Базовые понятия принципов программирования, верстка по дизайну и настройка
                  роутинга скринов
                </p>
              </div>
              <div className='modal-courses__button-wrapper'>
                <button
                  className='modal-courses__sign-up-button'
                  onClick={() => {
                    closeModal();
                    openModalForm();
                    setSelectProgram('Логика');
                  }}>
                  Записаться
                </button>
                {/* <span className='modal-courses__block-price'>{`${price2.toLocaleString(
                  'ru-RU'
                )} ₸`}</span> */}
              </div>
            </li>
            <li className='modal-courses__about-block modal-courses__about-block--3'>
              <div className='modal-courses__about-block-wrapper modal-courses__about-block-wrapper--3'>
                <span className='modal-courses__block-name'>Логика</span>
                <p className='modal-courses__block-description'>
                  Архитектура приложения, разделение логики от интерфейса, работа с серверами и
                  интеграция с API
                </p>
              </div>
              <div className='modal-courses__button-wrapper'>
                <button
                  className='modal-courses__sign-up-button'
                  onClick={() => {
                    closeModal();
                    openModalForm();
                    setSelectProgram('Логика');
                  }}>
                  Записаться
                </button>
                {/* <span className='modal-courses__block-price'>{`${price3.toLocaleString(
                  'ru-RU'
                )} ₸`}</span> */}
              </div>
            </li>
          </ul>
          <div className='modal-courses__show-program'>
            <Link
              className='modal-courses__show-program'
              onClick={() => {
                closeModal();
                document.documentElement.classList.remove('scroll-off');
              }}
              to='/#course-program'
              smooth>
              Узнайте подробнее про программу этапов курса
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ModalCourses;
