const Portfolio = () => {
  return (
    <section className='portfolio' id='portfolio' data-aos='fade-up'>
      <div className='portfolio__container'>
        <h2 className='portfolio__title section-title'>Что разработаете на курсе?</h2>
        <p className='portfolio__description'>
          Курс опирается на проект, который будет полигоном для экспериментов. На нём вы набьете
          руку и получите необходимые знания, чтобы успешно найти работу.
        </p>
        <div className='portfolio__content-wrapper'>
          <ul className='portfolio__works-list'>
            <li className='portfolio__work-item'>
              <h3 className='portfolio__work-title'>Продуктовый подход к разработке</h3>
              <p className='portfolio__work-description'>
                Данный проект основан на реальном приложении - Foody. Он находит рестораны со
                скидками и позволяет активировать их. Проект имеет готовый дизайн в Figma и
                документацию в Postman.
              </p>
            </li>
            <li className='portfolio__work-item'>
              <h3 className='portfolio__work-title'>Первый кейс в портфолио</h3>
              <p className='portfolio__work-description'>
                Исходный код проекта останется у вас и вы сможете показать его в качестве работы из
                портфолио.
              </p>
            </li>
          </ul>
          <img
            className='portfolio__smartphone-mockup'
            src={require('../assets/images/mockup.gif')}
            alt=''
          />
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
